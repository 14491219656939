
import {Component, Prop} from 'vue-property-decorator';
import Vue from 'vue';
import MicroService from "@/models/MicroService";
import MicroserviceRepository from "@/api/repositories/MicroserviceRepository";

@Component
export default class ResubscribeMicroService extends Vue {
  @Prop({required: true})
  private service!: MicroService;

  public isLoading: boolean = false;

  /**
   * is called when the user clicks on the card, calls passed callback function with the clicked microService
   * @private
   */
  private async onResubscribeClicked() {
    try {
      await MicroserviceRepository.subscribe(this.$route.params.id, this.service.id);
      this.dismiss(true);
    } catch (e) {
      this.$handleError(e, () => {
        switch (e.status) {
          case 404:
            this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.NO_MICROSERVICE_FOUND');
            break;
          case 409:
            this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.MICROSERVICE_ALREADY_SUBSCRIBED');
            break;
          case 412:
            this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.MISSING_IBAN');
            break;
          case 422:
            this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.MICROSERVICE_NOT_BOOKABLE');
            break;
          default:
            this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.MICROSERVICE_NOT_BOOKABLE');
        }
      });
    }
  }

  /**
   * closes the modal
   */
  private dismiss(deleted: boolean = false) {
    this.$emit('closeDialog', deleted);
  }
}
